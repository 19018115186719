import React from "react";

const HomePage = () => {
    return(
        <div className="App">
            <div className="background">
                <header className="app-header">
                    PORNO SFERA
                    <p className="app-subheader">Погрузись в свои фантазии</p>
                </header>
                <div className="button-group">
                    <a href="tg://resolve?domain=khtm_porn" className="button">Classic Porn</a>
                    <a href="tg://resolve?domain=neural_porn" className="button">Neural Porn</a>
                    <a href="tg://resolve?domain=trans_allure" className="button">Trans Porn</a>
                    <a href="tg://resolve?domain=pornosfera_bot?start=subscribe" className="button">Private access</a>
                </div>
            </div>
        </div>
    )
}

export default HomePage