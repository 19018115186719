import React from 'react';

const AdsPage = () => {
    return(
        <div className="App">
            <div className="background-blur">
                <header className="app-header">
                    PORNO SFERA
                    <p className="app-subheader">Вы можете заказать рекламу в телеграмм канале<br/>
                        <a href="tg://resolve?domain=khtm_porn">Какого хрена так мило?</a><br/>
                        по ссылкам ниже:
                    </p>
                </header>
                <div className="button-group">
                    <a href="https://tagio.pro/khtm" className="button button-tagio">Tagio</a>
                    <a href="https://teletarget.com/channel/@khtm_porn/" className="button button-teletarget">TeleTarget</a>
                </div>
            </div>
        </div>
    )
};

export default AdsPage;