import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/home';
import AdsPage from './components/ads';
import './App.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={< HomePage />} />
                <Route path="/ads" element={< AdsPage />} />
            </Routes>
        </Router>
    );
}

export default App;
